import { ajaxSend } from "../global/fetch";
import tumGlobal from "../global.js";
import tumCommon from "../common.js";
import { isCtuIgnoredAdulthood, showAdultWarning } from "../product/restrictAdult";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { initTuPopover } from "../global/popover";
import { toggleClass } from "../global/dom";
import { addEventDelegate, setHtml } from "../global/dom";
import { initIntersectionObserver } from "../intersectionObserver";

/**
 * @typedef {object} ItemListState
 * @property {number} productsPage_up номер первой страницы из показанных
 * @property {number} productsPage номер последней страницы из показанных
 * @property {number} productsPageSize
 * @property {number} totalPages
 * @property {number} totalProducts
 * @property {object[]} products
 * @property {object[]} productsSecondary
 * @property {string} viewMode
 * @property {boolean} restrictAdult
 * @property {number} minItemsLeftToShowBanner
 */

/**
 * @typedef {object} ItemListOptions
 * @property {string} url
 * @property {string} container
 * @property {(data: object) => object} dataMapperCallback позволяет получить данные из произвольного источника и привести их к нужному виду
 * @property {(state: ItemListState, data: object, prepend: boolean) => void} dataUpdateCallback выполняется после отображения результатов
 * @property {(state: ItemListState, prepend: boolean) => object} getUrlParamsCallback позволяет задать кастомные параметры для источника данных
 * @property {string} viewMode поддержка нескольких способов вывода
 * @property {boolean} renderOnInit если false, то компонент используется только при пагинации, а до нее показывается просто тот html, который был при загрузке страницы
 * @property {object} additionalData
 */

/**
 * @typedef {object} ItemList
 * @property {ItemListOptions} options
 * @property {ItemListState} state
 * @property {HTMLElement} container
 * @property {HandlebarsTemplateDelegate<any>} template
 * @property {(options: ItemListOptions) => void} init
 * @property {() => void} truncate если страница не последняя и на последней строке вывода осталось пустое место, то она скрывается и переносится на следующую страницу
 */

/** @type {ItemList} */
var productsList = {
	options: {
		url: '',
		container: '',
		dataMapperCallback: null,
		dataUpdateCallback: null,
		getUrlParamsCallback: null,
		viewMode: null,
		renderOnInit: false,
		additionalData: {}
	},
	state: {
		productsPage_up: 1,
		productsPage: 1,
		productsPageSize: 0,
		totalPages: 0,
		totalProducts: 0,
		products: [],
		productsSecondary: [],
		loadedPages: [],
		productsViewMode: 'mini',
		restrictAdult: true,
		minProductsLeftToShowBanner: 0,
		additional_items: []
	},
	container: null,
	template: null,
	init: function (options) {
		Object.assign(this.options, options);
		this.container = document.querySelector(this.options.container);
		if (!this.container) return;

		Object.assign(this.state, this.options);
		this.state.productsViewMode = this.options.viewMode || (tumGlobal.isMob() ? 'mini' : 'grid');

		this.initEvents();

		return this.getProductsList()
		.then(() => {
			if (this.options.renderOnInit) this.renderProductsList();
			this.truncate();
		});
	},
	initEvents: function () {
		addEventDelegate(this.container, 'click', '.products-list-next', () => {
			this.state.productsPage += 1;
			this.getProductsList()
			.then(() => {
				this.renderProductsList();
			});
		});

		addEventDelegate(this.container, 'click', '.products-list-prev', () => {
			this.state.productsPage_up -= 1;
			this.getProductsList(true)
			.then(() => {
				this.renderProductsList();
			});
		});
	},
	_updateProductsList: function (prepend, data) {
		if (this.options.dataMapperCallback && !data.noMap) data = this.options.dataMapperCallback(data);

		this.state.productsPageSize = this.state.productsPageSize || data.PageSize || 0;
		this.state.totalProducts = this.state.totalProducts || data.Total || 0;
		this.state.restrictAdult = this.state.restrictAdult || data.RestrictAdult || true;

		if (prepend) {
			this.state.products = data.Products.concat(this.state.products);
			this.state.productsSecondary = data.ProductsSecondary?.concat(this.state.productsSecondary) || [];
		}
		else {
			if (this.bannerRequired()) {
				let banner = this.state.banners.find(e => e.TypeID == -1);
				if (banner) {
					banner.isBanner = true;
					if (data.Products.length) {
						data.Products = [banner].concat(data.Products);
					}
					else if (data.ProductsSecondary.length) {
						data.ProductsSecondary = [banner].concat(data.ProductsSecondary);
					}
				}
			}

			this.state.products = this.state.products.concat(data.Products);

			if (data.ProductsSecondary)
			this.state.productsSecondary = this.state.productsSecondary?.concat(data.ProductsSecondary) || [];
		}

		this.state.totalPages = this.state.productsPageSize ? Math.ceil(this.state.totalProducts / this.state.productsPageSize) : 0;

		if (this.options.dataUpdateCallback) this.options.dataUpdateCallback(this.state, data, prepend);
	},
	getProductsList: function (prepend) {
		let self = this;

		if (this.state.productsPage == this.state.productsPage_up && (this.state.products.length || this.state.productsSecondary.length)) {
			this._updateProductsList(prepend, {
				Products: [],
				ProductsSecondary: [],
				noMap: true
			});
			return Promise.resolve();
		}

		if (!this.options.url) {
			return Promise.resolve();
		}

		let params = {};
		if (this.options.getUrlParamsCallback) params = this.options.getUrlParamsCallback(this.state, prepend);
		else params = { page: this.state.productsPage };

		return new Promise(resolve => {
			ajaxSend({ url: this.options.url, data: params }).then(data => {
				self._updateProductsList(prepend, data);
				resolve(data);
			});
		});
	},
	renderProductsList: function () {
		return loadPrecompiledTemplates(['products-list'])
		.then(() => {
			this.template = this.template || getPrecompiledTemplate('products-list');

			let products = this.state.products.concat(this.state.productsSecondary);

			let model = {
				filter: {
					page: this.state.productsPage,
					page_up: this.state.productsPage_up
				},
				ProductItems: this.state.products,
				ProductItemsSecondary: this.state.productsSecondary,
				ViewMode: this.state.productsViewMode,
				RestrictAdult: this.state.restrictAdult,
				ShowProductsPaging: this.state.productsPage < this.state.totalPages || (products.length && products.length < this.state.totalProducts),
				Banners: this.state.banners,
				CtuCloud: this.state.ctuCloud,
				ShowSoftMatch: this.state.productsSecondary.length,
				OnlyHasSecondaryProducts: !this.state.products.length && this.state.productsSecondary.length,
				additional_items: this.state.additional_items
			};

			Object.assign(model, this.options.additionalData);

			setHtml(this.container, this.template(model));
			initTuPopover(this.container);
			tumCommon.initCtuCloud();

			showAdultWarning(false, isCtuIgnoredAdulthood());
			this.truncate();
			initIntersectionObserver();
		});
	},
	productsLeft: function(){
		let result = this.state.totalProducts - (this.state.products?.length || 0) - (this.state.productsSecondary?.length || 0);
		return Math.max(result, 0);
	},
	bannerRequired: function(){
		return !!(this.state.productsPage > this.state.productsPage_up &&
			this.state.minProductsLeftToShowBanner && 
			this.productsLeft() > this.state.minProductsLeftToShowBanner &&
			this.state.banners?.length);
	},
	truncate: function(){
		if (!this.productsLeft()) return;

		let container = this.container.querySelector('.prod-cont');
		if (!container) return;

		let itemWidth = container.firstElementChild.offsetWidth - 1;
		let containerWidth = container.offsetWidth;
		let rowSize = Math.floor(containerWidth / itemWidth);
		let additionalItemsLength = this.state.additional_items?.filter(e => e.index < this.state.products.length).length || 0;
		let tail = (this.state.products.length + additionalItemsLength + this.state.productsSecondary.length + (this.state.productsSecondary.length ? 1 : 0)) % rowSize;

		if (tail) toggleClass([...container.children].slice(-tail), 'd-none tail', true);
	}
};

window.productsList = productsList;
export default productsList;