import { addEventDelegate } from "./dom";
import { isMob } from "./util";

/**
 * Регистрирует события, которые работают глобально, но нужный для них код загружается по требованию, а не сразу
 */
function initLazyModules() {
    addEventDelegate(document, 'click', '.toggleSearchMenu', () => {
        import("../searchMenu").then(({ tum_searchMenu }) => tum_searchMenu.toggleMenu());
    });

    addEventDelegate(document, 'click', '.home-catalog-link, .show-catalog', (element, e) => {
        if (!isMob()) {
            location.href = "/catalog";
            return;
        };

        e.preventDefault();
        import("../searchMenu").then(({ tum_searchMenu }) => {
            tum_searchMenu.toggleMenu().then(() => { 
                tum_searchMenu.scrollToCatalog();

                if (element.dataset.searchType) {
                    tum_searchMenu.setSearchType(element.dataset.searchType);
                }
            });
        });
    });

    addEventDelegate(document, 'click', '.mod-ord-window', (element, event) => {
        event.preventDefault();
        let idFirm = element.dataset.firmid;
        let idProd = element.dataset.productid;
        let params = { idFirm: idFirm, idProd: idProd };

        import("../showCaseManagement").then(({ management }) => management.getModalData(params));
    });

    addEventDelegate(document, 'click', '.toggleMenu', element => {
        import("../MainMenu").then(({ tum_mainMenu }) => tum_mainMenu.toggleMenu(element.dataset.highlight, element.dataset.showReadmore));
    });

    addEventDelegate(document, 'click', '.editTopListDate', (element) => {
        import("../product/editTopListDate.js").then(({ editTopListDate }) => {
            let productId = element.dataset.itemid;
            let previewId = element.dataset.previewid;
            editTopListDate.editTopListDate(productId, previewId);
        });
    });

    addEventDelegate(document, 'click', '.tum-miniatureCartBtn', (element, event) => {
        event.preventDefault();
        import("../cart/cartWidget").then(({ cartWidget }) => cartWidget.showCartWidget(element));
    });

    addEventDelegate(document, 'click', ['.topbar-openCatalog', '.tu-showCatalog'], element => {
        element.disabled = true;
        import("../catalogItems").then(({ catItems }) => {
            catItems.showCatalog().then(() => element.disabled = false);
        });
    });

    addEventDelegate(document, 'click', '.firm-edit-item', (element, event) => {
        event.preventDefault();
        import("../firm/firmStatusEdit").then(({ firmStt }) => firmStt.editFirmStatus(event, element));
    });

    addEventDelegate(document, 'click', '.task-update', (element) => {
        import("../tasks.js").then(({ tu_tasks }) => tu_tasks.updateButtonHandler(element));
    });

    addEventDelegate(document, 'click', '[data-edit-preview-connections]', (element) => {
        let productId = element.dataset.productid;
        let firmId = element.dataset.firmid;
        let ctuId = element.dataset.ctuid;

        import("../previews/connection_editor").then(({previewConnectionEditor}) => {
            previewConnectionEditor.open({ productId, ctuId, firmId });
        });
    });

    refreshLazyModules();
}

function refreshLazyModules(){
    for (let crud of document.querySelectorAll('[data-crud]')) {
        import("../crud.js").then(({ initCrud }) => initCrud(crud));
    }

    if (document.querySelector('.tu-modal-form')) {
        import("../form/form.modals").then(({ initFormModals }) => initFormModals());
    }

    if (document.querySelector('[data-tu-select]')) {
        import("../global/tu_select").then(({ initSelects }) => initSelects());
    }

    for (let timer of document.querySelectorAll('[data-timer]')) {
        import("../global/timer").then(({ initTimer }) => initTimer(timer));
    }

    if (document.querySelector('[data-submit-form]')) {
        import("../global/form").then(({ initFormUtils }) => initFormUtils());
    }

    for (let item of document.querySelectorAll('[data-highlight-search]')) {
        import("../global/highlight_search").then(({ initHighlightSearch }) => initHighlightSearch(item));
    }

    for (let item of document.querySelectorAll('[data-slider-auto-init]')) {
        import("../slider").then(module => {
            module.default.create({
                container: item,
                mobLoadMoreChevronDirection: 'right',
                autoCycling: true
            });
        });
    }

    if (document.querySelector('[data-range-slider]:not(.noUi-target)')) {
        import('../util/rangeSlider').then(({ initRangeSliders }) => initRangeSliders());
    }

    if (document.querySelector('[data-observe-visibility], [data-classes-when-shown], [data-classes-when-visible]')) {
        import('../intersectionObserver').then(({ initIntersectionObserver }) => initIntersectionObserver());
    }
    
    if (document.querySelector('[data-input-mask]')) {
        import('../util/inputMask').then(({ initInputMasks }) => initInputMasks());
    }
}

export { initLazyModules, refreshLazyModules };
