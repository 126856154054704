﻿/* global tumarket */

import * as $ from "jquery";
import "bootstrap/js/dist/collapse";
import tumGlobal from "../global.js";
import { ajaxSend } from "../global/fetch";
import searchHistory from "../searchHistory.js";
import { closeProgressbar, openProgressbar } from "../global/progressbar";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { addEventDelegate, hide, setHtml, toggleClass } from "../global/dom";

import "../../css/global/searchMenu.css";
import { searchWidgets } from "../navbar/searchSelect.js";
import { refreshLazyModules } from "../global/lazyHub.js";
import { setCookie } from "../global/cookie.js";

var searchSuggestions = {
	options: {
		url: {
			getSearchSuggestions: "/search/getSearchSuggestions",
		},
		container: ".searchWidget .suggestions",
		searchField: ".searchWidget input"
	},
	delayMs: 500,
	delayTimeoutId: null,
	/** @type {HTMLElement}  */
	container: null,
	template: null,
	init: function(options){
		Object.assign(this.options, options);
		this.initCallbacks();
	},
	getQuery: function(){
		return document.querySelector(this.options.searchField).value;
	},
	state: {
		lastQuery: "",
		catalogItems: [],
		previousCatalogItems: [],
		currentCatalogId: 0,
		previousCatalogName: "",
		currentCatalogName: ""
	},
	initCallbacks: function(){
		var self = this;

		if (!self.options.searchField) return;

		this.container = document.querySelector(this.options.container);

		addEventDelegate(document, 'click input', self.options.searchField, element => {
			this._showSuggestions(element.value);
		});

		tumGlobal.onClickOutside(`${this.options.container}, ${this.options.searchField}`, () => this.close());

		addEventDelegate(document, 'click', '.searchSuggestionLink', element => {
			searchHistory.add(element.dataset.query, element.dataset.type);
			openProgressbar()
			setTimeout(() => closeProgressbar(), 2000);
		});

		addEventDelegate(document, 'keydown', self.options.searchField, (element, e) => {
			if (e.key == "ArrowDown" && this.container) {
				e.preventDefault();
				document.querySelector(".searchSuggestionItem a").focus();
			}
		});

		addEventDelegate(document, 'keydown', '.searchSuggestionItem', (element, e) => {
			let items = [...this.container.querySelectorAll('.searchSuggestionItem')];
			let itemIndex = items.indexOf(element);

			if (e.key == 'ArrowDown') {
				e.preventDefault();
				if (items.length == itemIndex + 1) return;
				let item = items[itemIndex + 1];
				item.querySelector('a').focus();
			}

			if (e.key == "ArrowUp") {
				e.preventDefault();

				if (itemIndex == 0){
					document.querySelector(this.options.searchField).focus();
					return;
				}

				let item = items[itemIndex - 1];
				item.querySelector('a').focus();
			}
		});

		$(document).on('show.bs.dropdown', () => this.close());

		addEventDelegate(this.container, 'click', '.catalog-showMore', element => {
			$('.moreCatalogItems', this.container).collapse('show');
			toggleClass(element, 'd-none d-flex');
		});

		addEventDelegate(this.container, 'click', '.catalog-expand', element => {
			let id = element.closest('.tu-catalog-items').dataset.id;
			this.toggleCatalogChildren(id);
		});

		addEventDelegate(this.container, 'click', '.searchHistory-clear', () => {
			searchHistory.clear();
			hide(this.container.querySelector('.searchMenu-history'));
		});

		addEventDelegate(this.container, 'click', '.searchButton', () => searchWidgets.main.sendQuery());

		addEventDelegate(this.container, 'click', '.searchHistory-item', element => {
			let query = element.dataset.query;
			let type = element.dataset.type;
			searchWidgets.main.search(query, type);
		});

		addEventDelegate(this.container, 'change', '[data-use-catalog-grid]', element => {
			this.state.useCatalogGrid = element.checked;
			setCookie("useMobCatalogGrid", element.checked, 30);

			this.state.currentCatalogId = 0;
			this.state.currentCatalogName = "";
			this.state.previousCatalogId = 0;
			this.state.previousCatalogName = "";
			if (this.state.previousCatalogItems.length) {
				this.state.catalogItems = this.state.previousCatalogItems[0].items;
				this.state.previousCatalogItems = [];
			}

			this.update();
		});

		addEventDelegate(this.container, 'click', '[data-catalog-load]', element => {
			let id = Number(element.dataset.catalogLoad);
			
			return import("../catalogItems.js")
			.then(({catItems}) => catItems.getCatalog(id))
			.then(items => {
				let currentItem = this.state.catalogItems.find(e => e.id == id);
				this.state.previousCatalogItems.push({ items: this.state.catalogItems, id: this.state.currentCatalogId, name: this.state.currentCatalogName});
				this.state.previousCatalogName = this.state.currentCatalogName;
				this.state.currentCatalogId = id;
				this.state.currentCatalogName = currentItem.Name;
				this.state.catalogItems = items;
				this.update();
			});
		});

		addEventDelegate(this.container, 'click', '[data-catalog-back]', () => {
			let lastCatalog = this.state.previousCatalogItems.pop();
			this.state.catalogItems = lastCatalog.items;
			this.state.currentCatalogId = lastCatalog.id;
			this.state.currentCatalogName = lastCatalog.name;
			this.state.previousCatalogName = this.state.previousCatalogItems.slice(-1)[0]?.name;
			this.update();
		});
	},
	_showSuggestions: function(){
		if (this.delayTimeoutId) {
			clearTimeout(this.delayTimeoutId);
		}

		this.delayTimeoutId = setTimeout(() => {
			return loadPrecompiledTemplates(['search-dropdown', 'search-catalog-children'])
			.then(() => this.getSearchSuggestions(this.getQuery()))
			.then(() => this.loadCatalogItems())
			.catch(() => {})
			.then(data => this.show(data));
		}, this.delayMs);
	},
	updateState: function() {
		this.state.isMob = tumGlobal.isMob();
		this.state.searchHistory = searchHistory.get();
		this.state.hasSearchHistory = this.searchHistory?.length;
		this.state.searchQuery = this.getQuery();
		this.state.firmId = tumarket.firmId;
		this.state.firmName = tumarket.firmName;
		this.state.currentQuery = tumarket.search?.text;
	},
	update: function() {
		setHtml(this.container, this.template(this.state));
		refreshLazyModules();
	},
	getSearchSuggestions: function(query, type){
		var self = this;

		if (searchWidgets.main.getSearchType().code == "byFirmOffers") return Promise.resolve(null);

		if (query.length < 2) {
			//при клике на пустую строку показываем просто историю поиска, если она есть
			if (!query && searchHistory.get().length) {
				return Promise.resolve({});	
			}

			return Promise.resolve(null);
		}

		return new Promise((resolve, reject) => {
			ajaxSend({ url: self.options.url.getSearchSuggestions, data: { search: query } }).then(data => {
				if (data.result){
					data.query = query;
					data.searchType = type || searchWidgets.main.getSearchType().code;
					let result = self.updateSearchSuggestionsModel(data);
					this.state.searchSuggestions = result;
					resolve(result);
				} else {
					reject('getSearchSuggestions error');
				}
			});
		})
	},
	updateSearchSuggestionsModel: function(data){
		if (!data) return null;
		let query = data.query.toLowerCase();
		let items = data.items;

		items.forEach(e => {
			e.htmlText = e.text.replace(query, `<b>${query}</b>`);
			e.tuCount = e.info?.count
		});

		let ctusItem = data.items.find(e => e.info && e.info.ctus && e.info.ctus.length);
		let ctus = [];
		if (ctusItem) {
			ctus = ctusItem.info.ctus;
			ctus.forEach(e => { 
				e.text = ctusItem.text; 
				e.htmlText = ctusItem.htmlText 
			});
		}
		
		let firms = data.items.find(e => e.info && e.info.firms && e.info.firms.length)?.info.firms || [];
		firms.forEach(e => {
			let regex = new RegExp(`(${query})`, 'gi');
			e.htmlName = e.name.replace(regex, '<b>$1</b>');
			e.htmlType = e.firmType?.replace(regex, '<b>$1</b>');
			e.contacts.sort((a, b) => Number(b.isMain) - Number(a.isMain));
			e.phone = e.contacts.find(e => e.code = "contact-phone");
			e.whatsapp = e.contacts.find(e => e.isWhatsapp);
		});

		let result = { 
			items: items, 
			ctus: ctus, 
			firms: firms, 
			query: query,
			showFirmsFirst: data.searchType != "byGoods",
			noResults: !items.length && !ctus.length && !firms.length
		};

		return result;
	},
	loadCatalogItems: function(){
		if (this.state.catalogItems.length) return Promise.resolve();

		return import("../catalogItems.js")
		.then(({catItems}) => catItems.getCatalogItems())
		.then(result => {
			this.state.catalogItems = result;
		});
	},
	show: function(){
		this.template = this.template || getPrecompiledTemplate('search-dropdown');
		this.updateState();
		this.update();
		$(this.container).collapse('show');
	},
	close: function(){
		$(this.container).collapse('hide');
	},
	loadCatalogChildren: function(id){
		let container = this.container.querySelector(`.tu-catalog-items[data-id="${id}"] .tum-catalog-children`);
		if (container.classList.contains('loaded')) return Promise.resolve();

		container.classList.add('loaded');

		return import("../searchMenu.js").then(({tum_searchMenu}) => {
			return import("../catalogItems.js")
			.then(({catItems}) => catItems.getCatalog(id))
			.then(items => {
				setHtml(container, tum_searchMenu.renderCatalogChildren(items, container.matches('.l2') ? 2 : 1))
			});
		});
		
	},
	toggleCatalogChildren: function(id) {
		let item = this.container.querySelector(`.tu-catalog-items[data-id="${id}"]`);
		let children = item.querySelector('.tum-catalog-children');
		let icon = item.querySelector('.catalog-expand i');

		this.loadCatalogChildren(id)
		.then(() => {
			$(children).collapse('toggle');
			toggleClass(icon, 'fa-chevron-down fa-chevron-up');
		});
	},
};

export default searchSuggestions;