﻿import * as $ from "jquery";

import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/popover";
import dateFormat from "./lib/dateformat/dateformat";
import { ajaxSend } from "./global/fetch";
import { loadLib } from "./global/loadlib";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";
import { isMob } from "./global/util";
import { newUuid } from "./global/uuid";
import { highlight as hightlightElements, focusInput as _focusInput } from "./global/dom";

let tumGlobal = {
	data: {
		loadedTemplates: [],
		templateDependencies: {}
	},
	isDev: function(){
		return location.href.indexOf("localhost") > -1 || location.href.indexOf("dev.") > -1;
	},
	getFullDate: function(){
		let date = new Date();
		return dateFormat(date, `HH:mm, ${date.toLocaleString('ru', { year: 'numeric', month: 'long', day: 'numeric' })} (${date.toLocaleString('ru', { weekday: 'long' })})`);
	},
	loadYmaps: function () {
		if (window.ymaps) return Promise.resolve();

		return new Promise(resolve => {
			window.ymapsCallback = resolve;
			loadLib({
				jsLink: "https://api-maps.yandex.ru/2.1.77/?lang=ru_RU&apikey=58d3ef21-0b56-4982-a66d-4f11b1f52d90&onload=ymapsCallback",
				conditionObject: window.ymaps
			});
		});
	},
	isMob: () => isMob(),
	isMobOrTablet: () => window.innerWidth < 992,
	copyToClipboard: function(text) {
		navigator.clipboard.writeText(text);
	},
	getFormData: function ($form) {
		let unindexed_array = $form.serializeArray();
		let indexed_array = {};

		$.map(unindexed_array, function (n) {
			indexed_array[n['name']] = n['value'];
		});

		return indexed_array;
	},
	//хелпер, чтобы закрывать всякие всплывашки по клику снаружи
	onClickOutside: function(item, callback){
		$(document).on('click', function(e){
			var $target = $(e.target);
			if ($(item).is($target) || $(item).has($target).length || !$(document.body).has($target).length) {
				return;
			}
			callback();
		})
	},
	//подсветить элемент
	/**
	 * 
	 * @param {JQuery<HTMLElement>} $item 
	 * @param {*} time 
	 */
	highlight: function($item, time){
		hightlightElements($item.toArray(), time);
	},
	scrollUp: function(){
		window.scrollTo({ top: 0, behavior: "smooth" });
	},
	scrollTo: function(el){
		$("html, body").animate({
			scrollTop: $(el).offset().top - 50
		});
	},
	togglePageScroll: function(){
		$('body').toggleClass('noscroll');
	},
	createGuid: function() {
		return newUuid();
	},
	createModal: function(options){
		if ($(`#${options.id}`).length) return Promise.resolve();

		return loadPrecompiledTemplates(['modal'])
		.then(() => {
			let template = getPrecompiledTemplate('modal');
			$('body').append(template(options));
		});
	},
	getTimestamp: function(){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/home/getTimestamp' }).then(data => {
				if (data.result) resolve(data.timestamp);
				else reject('getTimestamp error');
			});
		});
	},
	bindInput: function(selector, object, name, onChange, container){
		$(container || document).on('change', selector, function(){
			object[name] = $(this).is('input[type=checkbox]') ? $(this).prop('checked') : $(this).val();
			if (onChange) onChange($(this), object[name], name);
		});
	},
	focusInput: function(field){
		_focusInput(field[0]);
	},
	parseNumber: function(value) {
		value = value.replace(/\s/g, '').replace(',', '.');
		return Number(value);
	},
	distinct: function(value, index, self) {
		return self.indexOf(value) === index;
	},
	validTextXSS: function(txt) {
		var res = true;
		var value = new RegExp('<[^>]*>');
		if (value.test(txt)) {
			res = false;
		}
		return res;
	}
};

export default tumGlobal;