﻿/* global tumarket */

import * as $ from "jquery";
import tumGlobal from "../global.js";
import searchHistory from "../searchHistory.js";
import "bootstrap/js/dist/collapse";
import { addEventDelegate, onClickOutside, setHtml, smoothScrollTo, toggle, toggleClass } from "../global/dom";
import { handleError } from "../global/debug.js";

let searchWidgets = {};

function createSearchWidget(options){
	let instance = {
		options: {
			//https://docs.google.com/document/d/1R-WGv7E3tSx6L8KGrJ-RJjFImjO4J3OTC4ErTmEs_Yo/edit
			arrru: new Array('Я', 'я', 'Ю', 'ю', 'Ч', 'ч', 'Щ', 'щ', 'Ш', 'ш', 'Ж', 'ж', 'Э', 'э', 'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'З', 'з', 'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 'П', 'п', 'Р', 'р', 'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 'Ы', 'ы', 'Ь', 'ь', 'Ъ', 'ъ'),
			arren: new Array('Ya', 'ya', 'Yu', 'yu', 'Ch', 'ch', 'Shh', 'shh', 'Sh', 'sh', 'Zh', 'zh', 'Eh', 'eh', 'A', 'a', 'B', 'b', 'V', 'v', 'G', 'g', 'D', 'd', 'E', 'e', 'E', 'e', 'Z', 'z', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'F', 'f', 'H', 'h', 'C', 'c', 'Y', 'y', '``', '`', '"', '"'),
			concllatchars: new Array('э', 'ш', 'сх', 'цх', 'схх', 'уа', 'уу', 'зх', 'ех', 'эх', 'ь', 'ъ'),
			container: '.searchWidget-container'
		},
		/** @type {HTMLElement} */
		container: null,
		init: function (options) {
			Object.assign(this.options, options);
			this.container = document.querySelector(this.options.container);
	
			if (!this.container) return;
			let searchTypes = this.container.querySelector('.searchWidget-searchTypes');
			let searchTypesList = this.container.querySelector('.searchTypes-list');
	
			addEventDelegate(this.container, 'keypress', 'input', (element, event) => {
				if (event.charCode == 13) {
					this.sendQuery();
				}
			});
	
			addEventDelegate(this.container, 'click', '.searchType', element => {
				let code = element.dataset.code;
				this.setSearchType(code, true);
				this.toggleSearchTypes();
			});
	
			onClickOutside(searchTypes, () => {
				$(searchTypesList).collapse('hide');
			});
	
			let input = this._input();
			input.addEventListener('input', () => {
				//явно обновляем строку поиска, чтобы она синхронизировалась между всеми полями ввода (второе поле ввода в плавающей шапке, например)
				this.setQuery(input.value);
	
				if (tumarket.state.searchMenuLoaded) { 
					import("../searchMenu").then(({tum_searchMenu}) => {
						tum_searchMenu.setSearchQuery(this.getQuery()); 
					});
				}
			});
	
			addEventDelegate(this.container, 'click', '.clearField', () => {
				this.clear();
				this.focus();
			});
	
			addEventDelegate(this.container, 'click', '.searchButton', () => this.sendQuery(true));
			this.updateClearButton();
		},
		toggleSearchTypes: function(action){
			$(this.container.querySelector('.searchTypes-list')).collapse(action || 'toggle');
		},
		updateClearButton: function(){
			toggle(this.container.querySelector('.clearField'), this.getQuery());
		},
		setSearchType: function(code, isInternal) {
			let oldType = this.getSearchType();
	
			let searchTypes = this._searchTypes();
			for (let type of searchTypes) type.selected = false;
			searchTypes.find(e => e.code == code).selected = true;
	
			this.updateSearchTypes(oldType);

			//this.focus();
	
			//isInternal = true, если метод вызывается внутри компонента.
			//только в этом случае нужно также обновить тип поиска в меню поиска, иначе будет рекурсия
			if (isInternal && tumarket.state.searchMenuLoaded) {
				import("../searchMenu").then(({tum_searchMenu}) => {
					tum_searchMenu.setSearchType(code);
				});
			} 
	
			let isOffers = code == "byFirmOffers";
			$('.searchWidget-offers').toggleClass('d-none', !isOffers);

			let input = this._input();
			if (isOffers) {
				input.placeholder = `Найти в «${tumarket.firmName}»`;
			} else {
				input.placeholder = `Найти в ${tumarket.general.location.parentCityID ? tumarket.general.location.nameIN : tumarket.general.location.regionName}`;
			}

			this.sendQuery();
		},
		updateSearchTypes: function(oldType){
			let newType = this.getSearchType();

			toggleClass(this.container.querySelectorAll('.searchType'), 'active', false);
			toggleClass(this.container.querySelectorAll(`.searchType[data-code="${newType.code}"]`), 'active', true);
			toggleClass(this.container.querySelectorAll('.searchTypeIcon'), oldType.icon, false);
			toggleClass(this.container.querySelectorAll('.searchTypeIcon'), newType.icon, true);

			for (let elem of this.container.querySelectorAll('.searchTypeName-text')) {
				setHtml(elem, newType.text);
			}
		},
		sendQuery: function(focusSearch) {
			let searchText = this.getQuery();
			let searchType = this.getSearchType()?.type;
	
			if (!searchType) {
				searchType = 'offer';
				handleError(new Error('attempting search with empty type. Setting to default (offer)'), true, true);
			}
	
			if (searchText) {
				this.search(searchText, searchType);
			} else {
				if (focusSearch) this.focus();
			}
		},
		search: function(query, type){
			if (!query || !type) return;
	
			if (type == "firmOffer") {
				let url = new URL(location.href);
				url.searchParams.set("query", query);
				location.href = url.toString();
				return;
			}
	
			var hasLatinChars = /[a-z]/.test(query.toLowerCase());
			var hasConclLatin = this.isConclLatinContains(query.toLowerCase());
			var hasCapitalLetter = /[А-Я]/.test(query) || /[A-Z]/.test(query);
			var hasSpecialChars = /[ё!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(query);
	
			var href = `/search-${type}/${this.cyrill_to_latin(query.toLowerCase()).replace(" ", "%20")}`;
			if (hasLatinChars || hasConclLatin || hasCapitalLetter || hasSpecialChars) {
				href = `/search-${type}/query?query=${query.replace("+", "%2B").replace("&", "%26")}`;
			}
			searchHistory.add(query, type);
			location.href = href;
		},
		cyrill_to_latin: function (text) {
			for (var i = 0; i < this.options.arrru.length; i++) {
				var reg = new RegExp(this.options.arrru[i], "g");
				text = text.replace(reg, this.options.arren[i]);
			}
			return text;
		},
		isConclLatinContains: function (text) {
			var result = false;
			for (var i = 0; i < this.options.concllatchars.length; i++) {
				if (text.indexOf(this.options.concllatchars[i]) > -1) result = true;
			}
			return result;
		},
		getSearchType: function(){
			return this._searchTypes().find(e => e.selected);
		},
		getQuery: function(){
			return this._input()?.value.trim();
		},
		setQuery: function(query){
			if (!this.exists()) return;
			this._input().value = query;
			this.updateClearButton();
		},
		_input: function(){
			return this.container?.querySelector('input');
		},
		_searchTypes: function(){
			return tumarket.searchWidget.searchSelectOptionsList;
		},
		focus: function(){
			this._input().focus();
		},
		clear: function(){
			this._input().value = '';
			this._input().dispatchEvent(new Event('input', { bubbles: true }));
		},
		highlight: function(){
			tumGlobal.highlight($(this.container));
		},
		exists: function(){
			return !!this.container;
		},
		isFocused: function(){
			return this._input()?.matches(':focus') || false;
		},
		scrollTo: function(){
			smoothScrollTo(this.container);
		}
	};

	instance.init(options);

	return instance;
}

function initSearchWidget() {
	if (document.querySelector('.topbar.floating .searchWidget')) {
		searchWidgets.floating = createSearchWidget({ container: '.topbar.floating .searchWidget-container' });
	}
	
	if (document.querySelector('.topbar.main .searchWidget')) {
		searchWidgets.main = createSearchWidget({ container: '.topbar.main .searchWidget-container' });
	} else {
		searchWidgets.main = createSearchWidget({ container: '.searchWidget-container' }); //home page
	}

	if (document.querySelector('.custom-search-widget .searchWidget-container')) {
		searchWidgets.custom = createSearchWidget({ container: '.custom-search-widget .searchWidget-container' });
	}
}

window.searchWidgets = searchWidgets;
 
export { initSearchWidget, searchWidgets };