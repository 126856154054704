﻿/* global tumarket */

import * as $ from "jquery";
import tumCommon from "../common.js";
import tumGlobal from "../global.js";
import { bootstrapAlert } from "../global/alert";
import { ajaxSend } from "../global/fetch";
import { getCookie, setCookie } from "../global/cookie";

import "../../css/modules/miniatures/miniatures.css";

import { showAdultWarning } from "../product/restrictAdult.js";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { pauseYoutubeVideos, youtubePreview } from "../youtubePreview.js";
import { addEventDelegate, appendHtml, toggleClass } from "../global/dom";
import { isMob } from "../global/util";
import { showDialog } from "../global/dialog";

var min = {
	options: {
		needMoreHeightPlus: 50,
		IsAuth: false,
		ajaxURL: {
			addFavorite: '/FavoriteProducts/UpdateFavoriteProducts',
			removeFavorite: '/FavoriteProducts/RemoveFavoriteProduct',
			addFavoriteFirm: '/FavoriteProducts/UpdateFavoriteFirms',
			removeFavoriteFirm: '/FavoriteProducts/RemoveFavoriteFirm',
			getProductDetails: '/Search/GetProductMiniatures'
		},
		favProductsCookie: 'favorProducts2',
		favFirmsCookie: 'favorFirms'
	},
	init: function (options) {
		min.options = $.extend(min.options, options);
		min.initCallbacks();
		this.initImages();
	},
	initCallbacks: function () {
		var self = this;

		addEventDelegate(document, 'click', '.tum-dropleft-menu-show, .miniature-menuBtn2', (element, e) => {
			e.preventDefault();
			min.getMenuItems(element);
		});

		addEventDelegate(document, 'click', '.menu-action-show-map', (element, e) => {
			e.preventDefault();
			if ($('.ui-tooltip').length) {
				$('.ui-tooltip').remove();
			}
			min.showMap($(element));
		});

		addEventDelegate(document, 'click', '.tum-add-menu-close', (element, e) => {
			e.preventDefault();
			min.needMoreHeightRevert();
			element.closest('.dropdown-menu')?.classList.remove('d-block');
		});

		addEventDelegate(document, 'click', 'body', () => {
			if ($('.tum-product-test-warningPop').length > 0) $('.tum-product-test-warningPop').remove();
		});

		$(document).on('mouseup', function (e) {
			var $container = $(".b-product-dropdown.d-block");
			var $target = $(e.target);

			// if the target of the click isn't the container nor a descendant of the container
			if (!$container.is($target) && !$container.has($target).length && $container.length > 0
					&& !$('a', $target.closest('.product-card-menu')).hasClass('tum-dropleft-menu-show') && !$('.tum-errorObject').has(e.target).length) {
				min.needMoreHeightRevert();
				$($container).removeClass('d-block');
			}
		});

		addEventDelegate(document, 'click', '.menu-action-error', (element, e) => {
			e.preventDefault();
			var $elTU = $(element).closest('.product-card').find('.b-product-name a');
			var $elKA = $(element).closest('.firm-miniature').find('.b-product-name a');
			var $dropMenuContainer = $(element).closest('.card-menu').find('.tum-dropleft-menu-show');
			var id = $dropMenuContainer.attr('data-productid') || $dropMenuContainer.attr('data-firmid'); 
			var isTU = $elTU.length > 0;
			window.open('/sendcontentmistakes?text=' + (isTU ? $elTU.text() : $elKA.text()) + '&errorObject=' + (isTU ? 'product-mini' : 'firm-mini') + '&' + (isTU ? 'tuID='+id : 'kaID='+id), '_blank');
		});

		addEventDelegate(document, 'click', '.tum-add-favorite-firm', (element, e) => {
			e.preventDefault();

			let firmID = $(element).attr('data-firmid');
			self._addFirmToFavorites(firmID)
			.then(data => {
				if (data.result) {
					self._updateFavoriteButton_firm(firmID);
				}
			});
		});

		addEventDelegate(document, 'click', '.tum-del-favorite-firm', (element, e) => {
			e.preventDefault();

			let firmID = $(element).attr('data-firmid');
			self._removeFirmFromFavorites(firmID).then(data => {
				if (data.result) {
					self._updateFavoriteButton_firm(firmID);
				}
			});
		});

		addEventDelegate(document, 'click', '.tum-add-favorite', (element, e) => {
			e.preventDefault();
			if ($(element).closest('.product-card').hasClass('_adult-product')) return;

			let productID = $(element).attr('data-productid');
			self._addProductToFavorites(productID).then(data => {
				if (data.result) {
					tumCommon.updateFavoritesCount();
					self._updateFavoriteButton_product(productID);
				}
			});
		});
		
		addEventDelegate(document, 'click', '.tum-del-favorite', (element, e) => {
			e.preventDefault();

			let productID = $(element).attr('data-productid');
			self._removeProductFromFavorites(productID).then(data => {
				if (data.result) {
					tumCommon.updateFavoritesCount();
					self._updateFavoriteButton_product(productID);
				}
			});
		});

		addEventDelegate(document, 'click', ".b-product-showDetails, .b-product-hideDetails", element => {
			var $product = $(element).closest(".product-card");

			self._toggleDetails($product);
		});

		addEventDelegate(document, 'click', ".b-product-showAllDetails", () => {
			for (let product of document.querySelectorAll('.product-card')) {
				this._toggleDetails(product, true);
			}
		});

		addEventDelegate(document, "click", ".product-card._adult-product", (element, e) => {
			e.preventDefault();
			e.stopPropagation();
			showAdultWarning(true);
		});

		addEventDelegate(document, 'click', ".miniatMenu-showSchedule", element => {
			self.getFirmSchedule($(element));
		});
		
		addEventDelegate(document, 'click', '.restrict-msg', () => {
			showAdultWarning(true);
		});

		addEventDelegate(document, 'click', '.product-card [data-go-to-video]', element => {
			let $product = $(element).closest('.product-card');
			let lastIndex = $('.mini-images-page', $product).last().attr('data-index');
			self.goToImage($product, lastIndex);
		});
	},
	_toggleDetails: function($product, value){
		let reverse = undefined;
		if (value != undefined) reverse = !value;

		$(".b-product-annotation", $product).toggleClass('min-product-annotation-collapsed', reverse);
		$('.b-product-hideDetails', $product).toggleClass('d-none', reverse);
		$('.b-product-showDetails, .b-product-showAllDetails', $product).toggleClass('d-none', value);
	},
	_getProductMiniatureMenu: function(id, type) {
		return new Promise(resolve => ajaxSend({ url: '/Search/GetProductMiniatureMenu', data: { id, type } }).then(resolve));
	},
	_getFirmMiniatureMenu: function(id, type) {
		return new Promise(resolve => ajaxSend({ url: '/Search/GetMiniatMenuItems', data: { itemID: id, type, isProduct: false } }).then(resolve));
	},
	_getMenuItems: function (btn) {
		let self = this;

		let miniature = btn.closest(".product-card, .firm-miniature");

		//кнопку меню находим заново внутри миниатюры, т.к. функция может быть запущена и другой кнопкой (.miniature-menuBtn2)
		btn = miniature.querySelector(".card-menu .tum-dropleft-menu-show");

		let cont = miniature.querySelector('.card-menu');
		let menuCont = cont.querySelector('.b-product-dropdown');

		let currentMenu = document.querySelector("body > .b-product-dropdown");
		currentMenu?.remove();

		let isFirm = !!cont.closest(".firm-miniature");
		let itemId = btn.getAttribute('data-productid') ? btn.getAttribute('data-productid') : btn.getAttribute('data-firmid');

		function showMenu(){
			if (isMob()) {
				showDialog({
					content: menuCont.innerHTML,
					bodyClasses: isFirm ? "firm-miniature-menu" : "product-miniature-menu",
					pinToBottom: true,
					showHeader: false,
					title: btn.dataset.title
				});

				return;
			}

			let elem = menuCont.cloneNode(true);
			toggleClass(elem, 'invisible', true);
			toggleClass(elem, isFirm ? "firm-miniature-menu" : "product-miniature-menu", true);
			elem.setAttribute('data-error-object', isFirm ? 'firm-mini' : 'product-mini');
			elem.setAttribute('data-error-itemid', itemId);
			document.body.appendChild(elem);

			self.updateMenuPosition(miniature);
		}

		if (!menuCont?.childNodes.length) {
			
			let type = btn.getAttribute('data-typemenu') || 0;
			let isProduct = !!btn.getAttribute('data-productid');
			let getMenu = isProduct ? this._getProductMiniatureMenu : this._getFirmMiniatureMenu;

			getMenu(itemId, type)
			.then(data => {
				if (data.result) {
					let html = '';
					if (isProduct) html = getPrecompiledTemplate("product-mini-menu")(JSON.parse(data.value));
					else html = getPrecompiledTemplate('firm-mini-menu')(JSON.parse(data.value));
					
					appendHtml(menuCont, html);
					
					showMenu();
				}
			});
		} else {
			showMenu();
		}
	},
	getMenuItems: function(btn){
		loadPrecompiledTemplates([
			'product-mini-menu',
			'firm-mini-menu'
		]).then(() => this._getMenuItems(btn));
	},
	updateMenuPosition: function(miniature) {
		let menu = document.querySelector("body > .b-product-dropdown");
		let miniatureBox = miniature.getBoundingClientRect();
		let scrollTop = window.scrollY;

		menu.style.top = `${miniatureBox.top + scrollTop}px`;
		let screenWidth = window.innerWidth;
		let width = Math.max.apply(null, [310, miniatureBox.width]);

		let left = (screenWidth > miniatureBox.left + width && miniatureBox.left > 0) ? miniatureBox.left : screenWidth - width - 10;

		menu.style.width = `${width}px`;
		menu.style.left = `${left}px`;

		//до этого момента высота меню неизвестна, поэтому всё в такой последовательности. 
		//выше top задаем только чтобы не прыгала высота страницы, потому что меню добавляется в конец
		toggleClass(menu, 'd-block', true);

		let menuBox = menu.getBoundingClientRect();
		menu.style.top = `${miniatureBox.top + scrollTop + (miniatureBox.height - menuBox.height)/2}px`;
		toggleClass(menu, 'invisible', false);
	},
	showMap: function ($btn) {
		let long = $btn.attr('data-long');
		let lat = $btn.attr('data-lat');
		let firmID = $btn.attr('data-firmid') || $btn.closest('.product-card').find('.tum-dropleft-menu-show').attr('data-firmid');

		let params = {
			long: long,
			lat: lat,
			firmID: firmID
		};

		tumCommon.showFirmOnMap(params);
	},
	needMoreHeight: function ($btn) {
		var $hDiv = $btn.closest('.product-card');
		if (!!$hDiv && $hDiv.hasClass('need-more-height')) {
			var hMnu = $($hDiv.find('.dropdown-menu')).height() + this.options.needMoreHeightPlus;
			var hDiv = $hDiv.height();
			if (hMnu > hDiv) {
				$hDiv.height(hMnu);
			}
		}
	},
	needMoreHeightRevert: function () {
		var $hDiv = $($(".b-product-dropdown.d-block")).closest('.product-card');
		if (!!$hDiv && $hDiv.hasClass('need-more-height')) {
			$hDiv.removeAttr('style');
		}
	},
	_addFirmToFavorites: function(firmID) {
		let cookie = JSON.parse(getCookie(this.options.favFirmsCookie) || '[]');
		if (!cookie.includes(firmID)) cookie.push(firmID);
		
		if (!tumarket.isAuthenticated) {
			setCookie(this.options.favFirmsCookie, JSON.stringify(cookie), 10);
			return Promise.resolve({ result: true });
		}

		//после авторизации избранное из куки нужно сохранить в бд вместе с данным товаром, а куку удалить
		setCookie(this.options.favFirmsCookie, '[]', -1);
		let favorites = cookie.map(e => ({ firmID: e }));

		return new Promise(resolve => {
			ajaxSend({ url: this.options.ajaxURL.addFavoriteFirm, data: { firmIDs: favorites } }).then(resolve);
		});
	},
	_removeFirmFromFavorites: function(firmID) {
		let cookie = JSON.parse(getCookie(this.options.favFirmsCookie) || '[]');
		cookie = cookie.filter(e => e != firmID);
		
		if (!tumarket.isAuthenticated) {
			setCookie(this.options.favFirmsCookie, JSON.stringify(cookie), 10);
			return Promise.resolve({ result: true });
		}

		return new Promise(resolve => {
			ajaxSend({ url: this.options.ajaxURL.removeFavoriteFirm, data: { id: firmID } }).then(resolve);
		});
	},
	_updateFavoriteButton_product: function(productID){
		let buttons = $(`.tum-add-favorite[data-productid=${productID}], .tum-del-favorite[data-productid=${productID}]`);
		buttons.toggleClass('d-none');
	},
	_updateFavoriteButton_firm: function(firmID){
		let buttons = $(`.tum-add-favorite-firm[data-firmid=${firmID}], .tum-del-favorite-firm[data-firmid=${firmID}]`);
		buttons.toggleClass('d-none');
	},
	_addProductToFavorites: function(productID){
		let cookie = JSON.parse(getCookie(this.options.favProductsCookie) || '{}');

		cookie[productID] = new Date();
		
		if (!tumarket.isAuthenticated) {
			setCookie(this.options.favProductsCookie, JSON.stringify(cookie), 10);
			return Promise.resolve({ result: true });
		}

		//после авторизации избранное из куки нужно сохранить в бд вместе с данным товаром, а куку удалить
		setCookie(this.options.favProductsCookie, '{}', -1);
		let favorites = Object.keys(cookie).map(e => ({ ProductID: Number(e) }));

		return new Promise(resolve => {
			ajaxSend({ url: this.options.ajaxURL.addFavorite, data: { productIDs: favorites } }).then(resolve);
		});
	},
	_removeProductFromFavorites: function(productID){
		let cookie = JSON.parse(getCookie(this.options.favProductsCookie) || '{}');
		delete cookie[productID];
		
		if (!tumarket.isAuthenticated) {
			setCookie(this.options.favProductsCookie, JSON.stringify(cookie), 10);
			return Promise.resolve({ result: true });
		}

		return new Promise(resolve => {
			ajaxSend({ url: this.options.ajaxURL.removeFavorite, data: { id: productID } }).then(resolve);
		});
	},
	getDate: function () {
		var date = new Date().toLocaleString('ru', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric'
		});
		var time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
		var res = `${date} ${time}`;
		return res;
	},
	goToImage: function($product, index) {
		var $cont = $(".mini-images", $product);
		var $img = $cont.find("img");

		var $page = $('.mini-images-page[data-index="' + index + '"]', $cont);
		let isVideo = $page.is('[data-video]');
		$('.mini-header-show-video', $product).toggleClass('d-none', !isVideo);

		var src = $page.attr("data-src");
		$img.attr("src", src);

		$(".mini-images-page", $cont).removeClass("active");
		$page.addClass("active");
	},
	initImages: function(){
		var self = this;
		let touchstartX = 0;
		let touchendX = 0;

		$(document).on('mouseenter', ".mini-images-page-area", function() {
			let $product = $(this).closest('.product-card');
			let index = $(this).attr('data-index');
			
			self.goToImage($product, index);
		});

		addEventDelegate(document, 'click', '.mini-header-show-video', element => {
			let $product = $(element).closest('.product-card');
			self.toggleVideo($product);
		});

		addEventDelegate(document, 'click', '.mini-header-hide-video', element => {
			let $product = $(element).closest('.product-card');
			self.toggleVideo($product);
			pauseYoutubeVideos();
		});

		$(document).on('touchstart', '.mini-images-swipeArea', function(event) {
			event.preventDefault();
			event.stopPropagation();
			touchstartX = event.changedTouches[0].screenX;
		});
		
		$(document).on('touchend', '.mini-images-swipeArea', function(event) {
			event.stopPropagation();
			touchendX = event.changedTouches[0].screenX;
			handleGesture(this);
		}); 
		
		$(document).on('touchmove', '.mini-images-swipeArea', function(e) {
			e.stopPropagation();

			var xStart, yStart = 0;
		
			xStart = e.touches[0].screenX;
			yStart = e.touches[0].screenY;
		
			var xMovement = Math.abs(e.touches[0].screenX - xStart);
			var yMovement = Math.abs(e.touches[0].screenY - yStart);

			if ((xMovement * 3) > yMovement) {
				e.preventDefault();
			}
		});

		function handleGesture(swipearea) {
			let imgCont = $(swipearea).closest('.mini-images');
			if (touchendX <= touchstartX) {
				self.nextImage(imgCont);
			}
			
			if (touchendX >= touchstartX) {
				self.previousImage(imgCont);
			}
		}
	},
	nextImage: function(imgCont){
		let $current = $(".mini-images-page.active", imgCont);
		let $next = $current.next();
		if (!$next.length) {
			$next = $current.prevAll().last();
		}

		$(".mini-images-page", imgCont).removeClass("active");
		$next.addClass("active");
		$("img", imgCont).attr("src", $next.attr("data-src"));

		let $product = imgCont.closest('.product-card');
		let isVideo = $next.is('[data-video]');
		$('.mini-header-show-video', $product).toggleClass('d-none', !isVideo);
	},
	toggleVideo: function($product){
		let $showVideoBtn = $('.mini-header-show-video', $product);
		

		let videoPreview = $('.mini-product-video .youtube-preview', $product);
		let showAsModal = videoPreview.is('[data-modal]');
		if (videoPreview.length) {
			youtubePreview.enableYoutubeVideo(videoPreview[0]);
		}

		if (showAsModal) return;

		$('.mini-product-video', $product).toggleClass('d-none');
		$('.mini-images', $product).toggleClass('d-none');
		$('.mini-header-hide-video', $product).toggleClass('d-none');
		
		$showVideoBtn.toggleClass('d-none');
		$('.miniature-top-btn', $product).toggleClass('invisible');
		$('.mini-header-discount', $product).toggleClass('d-none');
	},
	previousImage: function(imgCont){
		let $current = $(".mini-images-page.active", imgCont);
		let $prev = $current.prev();
		if (!$prev.length) {
			$prev = $current.nextAll().last();
		}

		$(".mini-images-page", imgCont).removeClass("active");
		$prev.addClass("active");
		$("img", imgCont).attr("src", $prev.attr("data-src"));

		let $product = imgCont.closest('.product-card');
		let isVideo = $prev.is('[data-video]');
		$('.mini-header-show-video', $product).toggleClass('d-none', !isVideo);
	},
	getFirmSchedule: function($btn){
		var $schedule = $btn.closest(".product-miniature-menu, .firm-miniature-menu, .schedule-container").find(".miniatMenu-schedule");
		var params = { firmID: $btn.attr("data-firmid"), depID: $btn.attr("data-depid") || 0 };

		if ($schedule.html()) {
			$schedule.toggleClass("d-none");
			return;
		}

		loadPrecompiledTemplates(['firm-schedule']).then(() => {
			ajaxSend({ url: '/FirmRequests/GetFirmSchedule', data: params }).then(data => {
				if (data.result) {
					var template = getPrecompiledTemplate('firm-schedule');
					var d = Object.assign({}, data, { localTime: tumGlobal.getFullDate() });
					var htmlOut = template(d);
					$schedule.html(htmlOut);
				} else {
					bootstrapAlert('Ошибка загрузки графика фирмы.');
				}
			});
		});
	}
};

export default min;
