﻿import "../css/modules/tu.m/tu_slider.css";
import { isCtuIgnoredAdulthood, showAdultWarning } from "./product/restrictAdult";
import { addEventDelegate, appendHtml, insertHtmlBefore, toggleClass } from "./global/dom";
import { isMob } from "./global/util";

/**
 * @typedef {Object} SliderOptions
 * @property {HTMLElement} container
 * @property {(callback: () => void) => void} getItems
 * @property {number} itemWidth
 * @property {boolean} addButtons
 * @property {number} totalCount
 * @property {string} customControls
 * @property {string} mobLoadMoreChevronDirection
 * @property {boolean} autoCycling
 */

let tu_slider = {
	/**
	 * 
	 * @param { SliderOptions } options 
	 * @returns 
	 */
	create: function (options) {
		if (options.container.classList.contains('slider-initialized')) {
			return null;
		}

		let slider = {
			/** @type { SliderOptions } */
			options: {
				container: null,
				getItems: null,
				itemWidth: 265,
				addButtons: true,
				totalCount: 0,
				customControls: '',
				mobLoadMoreChevronDirection: 'down',
				autoCycling: false
			},
			isLoading: false,
			listContainer: null,
			isLoaded: false,
			itemWidth: 0,
			size: 0,
			page: 1,
			totalPages: 1,
			_isMob: () => window.innerWidth < 768,
			init: function (options) {
				this.options = Object.assign({}, this.options, options);

				if (!this.options.container) return;
				this.listContainer = this.options.container.querySelector('.slider-list');
				
				this.size = this._getSize();
				if (!this.options.getItems || this.count() >= this.options.totalCount) this.isLoaded = true;
				this.itemWidth = this.options.itemWidth || this._getItemWidth();

				this._initEvents();

				this.updatePages();

				if (this.options.addButtons && this.totalPages > 1) this.renderButtons();
				this._updateMobButtons();
				this._updateMobCloser();
				this.renderPages();

				if (this.options.autoCycling) {
					this.initAutoCycling();
				}

				toggleClass(this.options.container, 'slider-initialized', true);
			},
			_initEvents: function () {
				let cont = this.options.container;

				addEventDelegate(cont, 'click', '.slider-next', () => this.go('>'));
				addEventDelegate(cont, 'click', '.slider-prev', () => this.go('<'));
				addEventDelegate(cont, 'click', '.slider-goToStart', () => this.go('<<'));
				addEventDelegate(cont, 'click', '.slider-goToEnd', () => this.go('>>'));
				addEventDelegate(cont, 'click', '[data-load-more]', () => this.loadMore());
				addEventDelegate(cont, 'click', '.slider-page', element => {
					let page = Number(element.getAttribute('data-page'));
					this._moveToPage(page);
				});

				if (this._isMob()) this.listContainer.addEventListener('scroll', () => this._updateMobButtons(), { passive: true });
			},
			_getSize: function () {
				let width = window.innerWidth;
				switch (true) {
					case (width >= 1280):
						return 4;
					case (width >= 1024):
						return 3;
					case (width >= 768):
						return 2;
					default:
						return 1;
				}
			},
			_getItemWidth: function () {
				return this.width() / this._getSize();
			},
			moveTo: function (position) {
				this.listContainer.scrollTo({ left: position, behavior: 'smooth' });
				this.updatePages();
			},
			_getItemsCallback: function (page) {
				this.isLoaded = true;
				this.isLoading = false;
				this.updatePages();
				this._updateMobCloser();
				this.options.container.querySelector('.slider-loadMoreCont')?.remove();

				if (!this._isMob()) {
					if (!page) this.go('>');
					else this._moveToPage(page);
				}

				showAdultWarning(false, isCtuIgnoredAdulthood());
			},
			loadMore: function(page) {
				if (this.isLoaded) return;
				
				if (!page) {
					this.page = this.totalPages - 1;
				}
				
				this.options.getItems(() => this._getItemsCallback(page));
			},
			_moveToPage: function (page, noLazyLoad) {
				if (page == this.page) return;
				
				if ((page == this.totalPages || this.count() != this.options.totalCount) && !this.isLoaded && !noLazyLoad) {
					this.loadMore(page);
				}
				else if (page <= this.totalPages && page > 0) {
					this.page = page;
					this.moveTo((this.page - 1) * this.itemWidth * this.size);
				}
			},
			totalWidth: function () {
				return this.listContainer.scrollWidth;
			},
			count: function(){
				return this.listContainer.childElementCount;
			},
			totalContentWidth: function(){
				return this.itemWidth * this.count();
			},
			width: function () {
				return this.listContainer.offsetWidth;
			},
			go: function(value) {
				switch(value) {
					case '>':
						this._moveToPage(this.page + 1);
						break;
					case '<':
						this._moveToPage(this.page - 1);
						break;
					case '<<':
						this._moveToPage(1);
						break;
					case '>>':
						if (this.isAtEnd()) this.loadMore();
						else this._moveToPage(this.totalPages, true);
						break;
				}
			},
			updatePages: function() {
				if (this.options.totalCount) {
					this.totalPages = Math.ceil(this.options.totalCount / this.size);
				} else {
					this.totalPages = Math.ceil(this.totalContentWidth() / (this.itemWidth*this.size));
				}
			},
			renderPages: function(){
				let renderPage = number => {
					return `<a class="mx-1 slider-page ${number == this.page ? 'font-weight-bold' : ''}" data-page="${number}" href="javascript:void(0)">${number}</a>`;
				}

				let pageNumbers = [];
				for (let i = 0; i < this.totalPages; i++) pageNumbers.push(i);

				let pages = pageNumbers.map(i => renderPage(i+1));

				let cont = this.options.container;
				let $pages = cont.querySelector('.slider-pages');
				if ($pages) $pages.innerHTML = pages.join('');

				//toggleClass(cont.querySelector('.slider-next'), 'disabled', this.page == this.totalPages);
				//toggleClass(cont.querySelector('.slider-prev'), 'disabled', this.page == 1);
				toggleClass(cont.querySelector('.slider-controls'), 'd-none', !this._isMob() && this.totalPages == 1);
			},
			_updateMobButtons: function(){
				let cont = this.options.container;
				toggleClass(cont.querySelector('.slider-goToEnd'), 'disabled', this.isAtEnd() && this.isLoaded);
				toggleClass(cont.querySelector('.slider-goToStart'), 'disabled', this.isAtStart());

				if (this.isAtStart()) this.page = 1;
				else if (this.isAtEnd()) { 
					this.page = this.totalPages;

					if (!this.isLoading) {
						this.loadMore();
					}
					this.isLoading = true;
				}
				else this.page = 2;
			},
			_updateMobCloser: function(){
				toggleClass(this.listContainer.querySelector('.slider-closer'), 'd-none', this._isMob() && !this.isLoaded);
			},
			isAtStart: function(){
				let position = this.listContainer.scrollLeft;
				return position < 50;
			},
			isAtEnd: function(){
				let position = this.listContainer.scrollLeft;
				return position > this.totalWidth() - this.width() - 50;
			},
			renderButtons: function(){
				let html = "";
				if (this._isMob()){
					// html = `<div class="text-right slider-controls d-flex justify-content-between">
					// 			<div>${this.options.customControls}</div>
					// 			<div>
					// 				<a href="javascript:void(0)" class="slider-goToStart fas fa-chevron-left mx-2"></a>
					// 				<a href="javascript:void(0)" class="slider-goToEnd fas fa-chevron-right mx-2"></a>
					// 			</div>
					// 		</div>`;
				} else {
					html = `<div class="text-right slider-controls d-flex justify-content-between">
								<div>${this.options.customControls}</div>
								<div>
									<a href="javascript:void(0)" class="slider-prev fas fa-chevron-left mx-2" aria-label="Назад"></a>
									<span class="slider-pages d-none"></span>
									<a href="javascript:void(0)" class="slider-next fas fa-chevron-right mx-2" aria-label="Вперед"></a>
								</div>
							</div>`;
				}

				insertHtmlBefore(this.listContainer, html);
				if (this._isMob() && !this.isLoaded) this.renderLoadMoreButton();
			},
			renderLoadMoreButton: function(){
				let html = isMob() ?
							`<div class="slider-loadMoreCont">
								<button class="tu-btn tu-btn-secondary w-100 m-3" data-load-more>
								  Показать ещё
								</button>
							</div>` : 
							`<div class="slider-loadMoreCont">
								<a href="javascript:void(0)" class="slider-loadMore fas fa-chevron-circle-${this.options.mobLoadMoreChevronDirection || 'down'} fa-lg" aria-label="Далее"></a>
							</div>`;
				appendHtml(this.listContainer, html);
			},
			initAutoCycling: function(){
				this.cycleInterval = setInterval(() => {
					if (this.isAtEnd()) {
						this.moveTo(0);
						return;
					}

					this.moveTo(this.listContainer.scrollLeft + (isMob() ? window.innerWidth : 340));
				}, 8000);
			}
		};

		slider.init(options);

		return slider;
	}
};

export default tu_slider;