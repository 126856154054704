﻿/* global tumarket */

import * as $ from "jquery";
import tu_slider from "../slider.js";
import { ajaxSend } from "../global/fetch";
import { setCookie } from "../global/cookie";
import "../../css/modules/tu.m/catalog/otherCities.css";
import "readmore-js";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { initTuPopover } from "../global/popover";
import { addEventDelegate, insertHtmlAfter, insertHtmlBefore, prependHtml, toggleClass } from "../global/dom";
import tumGlobal from "../global.js";
import { isMob } from "../global/util";

var otherCitiesSearch = {
	options: {
		url: {
			getProductMiniatures: "/search/getproductminiatures"
		},
		firstCity: {},
		otherCities: []
	},
	init: function (options) {
		Object.assign(this.options, options);

		if (!document.querySelector('.tum-search-otherCitiesCont')) return;

		addEventDelegate(document, 'click', '.tum-search-showOtherCitySlider', (element, event) => {
			event.preventDefault();
			toggleClass(".tum-search-otherCity", "d-none", false);

			var city = element.closest(".tum-search-otherCity");
			var productIds = city.getAttribute("data-productids").split(",").map(e => Number(e));
			var linksAfter = city.getAttribute("data-linksafter");
			var name = city.getAttribute("data-name");
			toggleClass(city, 'd-none', true);
			this.getOtherCitySlider(productIds, linksAfter, name);
		});

		addEventDelegate(document, 'click', ".tum-search-hideOtherCitySlider", () => {
			tumGlobal.highlight($('.tum-search-otherCities'), 1000);
		});

		addEventDelegate(document, "click", ".tum-search-changeRegion a, a.tum-search-changeRegion", (element, e) => {
			e.preventDefault();
			var url = element.getAttribute("href");
			if (url == "#" || url == "javascript:void(0)") return;

			setCookie("geo_resetLocation", "1", 1);
			location.href = url;
		});

		if (this.options.otherCities) {
			this.renderOtherCities().then(() => {
				if (this.options.firstCity) {
					this.initSliderProducts(this.options.firstCity.slider, this.options.firstCity.linksAfter, this.options.firstCity.hrefToMarketCity);
					this.renderProductSlider().then(() => {
						prependHtml(
							".tum-search-otherCitySlider .visibility-sliders", 
							`<a href="javascript:void(0)" data-name="${this.options.firstCity.anyCityName}"
								class="tum-search-hideOtherCitySlider tum-dark-red font-weight-bold"
								data-productids="${this.options.firstCity.productIds}">
								${this.options.firstCity.anyCityName}:
							</a>`
						);
			
						insertHtmlAfter(".tum-search-otherCitySlider .visibility-sliders", this.options.firstCity.linksAfter);
						this.initProductSlider();
					});
				}
			});
		}
	},
	getOtherCitySlider: function (productIds, linksAfter, name) {
		if (!productIds) {
			return;
		}

		ajaxSend({ url: this.options.url.getProductMiniatures, data: { productIDs: productIds, disableShowCity: true } }).then(data => {
			if (data.result) {
				this.initSliderProducts(data.Items, linksAfter);
				this.renderProductSlider().then(() => {
					let container = document.querySelector(".tum-search-otherCitySlider .visibility-sliders");
					prependHtml(container, `<a href="javascript:void(0)" data-productids="${productIds.join(',')}" data-name="${name}" class="tum-search-hideOtherCitySlider tum-dark-red font-weight-bold">${name}:</a>`)
					container.nextElementSibling.remove();
					insertHtmlAfter(container, linksAfter);
					this.initProductSlider();
				});
			}
		});
	},
	initSliderProducts: function (miniatures, linksAfter) {
		if (miniatures) {
			for (let item of miniatures) {
				item.contextType = 'otherCities';
			}
		}

		var products = {
			Items: miniatures,
			MoreItemsLink: linksAfter
		};
		
		this.options.sliderProducts = products;
	},
	renderProductSlider: function () {
		return loadPrecompiledTemplates(['product-slider']).then(() => {
			var template = getPrecompiledTemplate('product-slider');
			this.options.sliderProducts.isSlider = true;
			this.options.sliderProducts.isMob = isMob();
			this.options.sliderProducts.mobGrid = false;
			var htmlOut = template(this.options.sliderProducts);
			
			document.querySelector(".tum-search-otherCitySlider .visibility-sliders").innerHTML = htmlOut;
			toggleClass(".tum-search-otherCitySlider", 'd-none', false);
			
			initTuPopover();
		});
	},
	initProductSlider: function () {
		let sliderContainer = document.querySelector('.tum-search-otherCitySlider .tu-slider');
		let totalProductIds = sliderContainer.previousElementSibling.getAttribute('data-productids').split(',')

		this.slider = tu_slider.create({ 
			container:  sliderContainer,
			getItems: (callback) => {
				let productIDs = totalProductIds.slice(tumarket.defaultSliderSize);
				loadPrecompiledTemplates(['product-mini-wrapper']).then(() => {
					return ajaxSend({ url: '/search/GetProductMiniatures', data: { productIDs: productIDs, isNeedLinkUnder: true, disableShowCity: true } });
				}).then(data => {
					if (data.result) {
						let template = getPrecompiledTemplate('product-mini-wrapper');
						for (let item of data.Items) {
							item.isSlider = true;
							item.isMob = isMob();
							item.contextType = "otherCities";
						}

						let newItems = data.Items.map(e => template(e)).join("");

						insertHtmlBefore(sliderContainer.querySelector('.slider-list .slider-additional-slide'), newItems);
						callback();
						initTuPopover();
					}
				});
			},
			totalCount: totalProductIds.length + 1
		});
	},
	renderOtherCities: function() {
		return loadPrecompiledTemplates(['other-cities']).then(() => {
			var template = getPrecompiledTemplate('other-cities');
			var htmlOut = template({ OtherCities: this.options.otherCities});
			document.querySelector(".tum-search-otherCitiesCont").innerHTML = htmlOut;
		});
	}
};

window.otherCitiesSearch = otherCitiesSearch;
export default otherCitiesSearch;